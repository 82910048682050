/* Webフォント
---------------------------------------------------------- */
const webfont = () => {
	window.WebFontConfig = {
		google: {
			families: [
				'Noto+Sans+JP:400,500,700,900',
				// 'Noto+Serif+JP:400,600',
				'Montserrat:600,700',
			],
		},
		active: () => {
			sessionStorage.fonts = true;
		},
	};
	(() => {
		let wf = document.createElement('script');
		wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
		wf.type = 'text/javascript';
		wf.async = 'true';
		let s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(wf, s);
	})();
};

/* グローバルナビ
---------------------------------------------------------- */
const gnavAction = () => {
	const body = document.body;
	const gnavOpenClass = 'is-nav-opened';
	const gnavCloseClass = 'is-nav-closed';
	const gnav = document.getElementById('gnav');

	window.addEventListener('touchmove', (e) => {
		if (body.classList.contains(gnavOpenClass) && e.target === gnav) {
			e.preventDefault();
		} else {
			e.stopPropagation();
		}
	}, { passive: false });

	if (body.classList.contains(gnavOpenClass)) {
		body.classList.remove(gnavOpenClass);
		body.classList.add(gnavCloseClass);
	} else {
		body.classList.remove(gnavCloseClass);
		body.classList.add(gnavOpenClass);
	}
};

const gnavToggle = () => {
	const gnavToggle = document.querySelectorAll('.js-gnav-toggle');
	const overlay = document.getElementById('overlay');

	// ハンバーガーメニュークリック
	if (gnavToggle !== null) {
		Array.prototype.forEach.call(gnavToggle, (el) => {
			el.addEventListener('click', (e) => {
				gnavAction(e);
			});
		});
	}

	// 背景クリック
	if (overlay !== null) {
		overlay.addEventListener('click', (e) => {
			gnavAction(e);
		});
	}
};

const disableScroll = (el) => {

	const body = document.body;
	const className = 'is-scroll-disabled';

	// el.scrollTop = 1;

	window.addEventListener('touchmove', (event) =>  {
		if( body.classList.contains(className) && event.target === el ){
			event.preventDefault();
		} else {
			event.stopPropagation();
		}
	},{ passive: false });

	// if( body.classList.contains(className) ){
	// 	body.classList.remove(className);
	// } else {
	// 	body.classList.add(className);
	// }

};

/* 要素のフェードイン
---------------------------------------------------------- */
const inView = () => {
	const items = document.querySelectorAll('.js-inview');
	const isInview = 'is-inview';

	if (items.length < 1) return false;

	const cb = (entries, observer) => {
		entries.forEach((entry) => {

			const toggle = entry.target.dataset.inviewToggle;

			// console.log(entry);

			if (entry.isIntersecting) {

				entry.target.classList.add(isInview);

			} else {

				if ( toggle ) {
					entry.target.classList.remove(isInview)
				}

			}

		});
	};

	const options = {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: [0.1]
	};

	const io = new IntersectionObserver(cb, options);

	items.forEach((item) => {
		io.observe(item);
	});

	const transitionLast = document.querySelectorAll('.transition-last');
	transitionLast.forEach(el => {
		const parent = el.parentElement;
		const next = parent.dataset.next;
		const nextEl = document.querySelector(`.${next}`);

		el.addEventListener('transitionend', () => {
			el.parentElement.classList.add('is-transitionend');

			if ( parent.classList.contains('is-inview') ) {
				if ( !next || next === 'hero-cost' && !isPc() ) return false;
				nextEl.classList.add('is-inview');
			}
		});
	});
};

/* spanWrap
---------------------------------------------------------- */
const spanWrap = () => {

	const targets = document.querySelectorAll('.js-span-wrap-text');

	targets.forEach( target => {
		const nodes = [...target.childNodes];
		const inners = target.querySelectorAll('span');

		if ( inners ) {
			inners.forEach( inner => {
				const childNodes = [...inner.childNodes];
				spanWrapText(inner, childNodes);
			});
		}

		spanWrapText(target, nodes);

		const items = target.querySelectorAll('.s_t');
		const length = items.length;

		items.forEach( (item, i) => {
			const delay = ( target.dataset.delay ) ? i * target.dataset.delay : i * 0.1 ;
			item.style.transitionDelay = `${delay}s`;

			if ( i+1 == length ) {
				item.classList.add('s_t--last');
			}
		});

		const last = document.querySelectorAll('.s_t--last');
		last.forEach( el => {
			el.addEventListener('transitionend', () => {
				if ( target.classList.contains('is-inview') ) {
					target.classList.add('is-transitionend');
				}
			});
		});

	});

	function spanWrapText(target, nodes) {
		let spanWrapText = ""
		nodes.forEach((node, i) => {
			if (node.nodeType == 3) {//テキストの場合
				const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
				//spanで囲んで連結
				spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
					return acc + `<span class="s_t">${v}</span>`
				}, "");
			} else {//テキスト以外
				//<br>などテキスト以外の要素をそのまま連結
				spanWrapText = spanWrapText + node.outerHTML
			}
		});
		target.innerHTML = spanWrapText;
	}

}

/* opening
---------------------------------------------------------- */
const opening = () => {

	const slider01 =  document.querySelector('.js-hero-01-slider-01');
	const slider02 =  document.querySelector('.js-hero-01-slider-02');

	if ( !slider01 ) return false;

	const title =  document.querySelector('.js-hero-01-title');
	const titleLast =  title.querySelector('.circle').childNodes[0];
	const peanuts =  document.querySelector('.js-hero-01-peanuts');

	if ( isPc () ) {
		slider02.addEventListener('transitionstart', () => {
			setTimeout(() => {
				title.classList.add('is-inview');
			}, 400)
		});

		titleLast.addEventListener('transitionend', () => {
			peanuts.classList.add('is-inview');
		});

	} else {

		setTimeout(() => {
			title.classList.add('is-inview');
		}, 400)

		titleLast.addEventListener('transitionend', () => {
			slider01.classList.add('is-inview');
		});

		slider01.addEventListener('transitionstart', () => {
			setTimeout(() => {
				peanuts.classList.add('is-inview');
			}, 400)
		});

	}

}

/* アコーディオン
---------------------------------------------------------- */
const accordion = () => {

	const details = document.querySelectorAll(".js-details");
	const RUNNING_VALUE = "running";
	const IS_OPENED_CLASS = "is-opened";

	details.forEach((el) => {
		const summary = el.querySelector(".js-summary");
		const content = el.querySelector(".js-content");

		summary.addEventListener("click", (event) => {
			event.preventDefault();

			if (el.dataset.animStatus === RUNNING_VALUE) {
				return;
			}

			if (el.open) {

				el.classList.toggle(IS_OPENED_CLASS);

				const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
				el.dataset.animStatus = RUNNING_VALUE;

				closingAnim.onfinish = () => {
					el.removeAttribute("open");
					el.dataset.animStatus = "";
				};

			} else {

				el.setAttribute("open", "true");
				el.classList.toggle(IS_OPENED_CLASS);
				const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
				el.dataset.animStatus = RUNNING_VALUE;

				openingAnim.onfinish = () => {
					el.dataset.animStatus = "";
				};
			}
		});
	});

	const animTiming = {
		duration: 400,
		easing: "ease-out"
	};

	const closingAnimKeyframes = (content) => [
		{
			height: content.offsetHeight + 'px',
			// opacity: 1,
		}, {
			height: 0,
			// opacity: 0,
		}
	];

	const openingAnimKeyframes = (content) => [
		{
			height: 0,
			// opacity: 0,
		}, {
			height: content.offsetHeight + 'px',
			// opacity: 1,
		}
	];
}

/* gsap
---------------------------------------------------------- */
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const gsapAction = () => {

	// const copy = document.querySelector('.js-hero-copy');

	// if ( copy ) {

	// 	gsap.to(copy, {
	// 		scrollTrigger: {
	// 			trigger: copy,
	// 			start: 'bottom+=100 bottom',
	// 			end: 'bottom+=100 bottom',
	// 			endTrigger: 'html',
	// 			toggleClass: { targets: '.hero-01', className: 'is-scroll' },
	// 		}
	// 	});

	// }
	const body = document.body;
	const works = document.querySelector('.works__inner');
	const pagetop = document.querySelector('.pagetop');

	gsap.to(body, {
		scrollTrigger: {
			trigger: body,
			start: "top+=5% top",
			// end: 'top bottom',
			// markers: true,
			toggleClass: { targets: body, className: 'is-scrolled'},
		}
	});

	if ( works ) {
		gsap.to(works, {
			scrollTrigger: {
				trigger: works,
				start: 'top bottom',
				end: 'bottom bottom-=10%',
				// markers: true,
				toggleClass: { targets: pagetop, className: 'is-hidden'},
			}
		});
	}

	const parallax = document.querySelectorAll('.js-parallax');

	if ( parallax ) {
		parallax.forEach(el => {
			const y = el.dataset.y || -100;
			gsap.to(el, {
				y: y,
				scrollTrigger: {
					trigger: el,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 0.3,
					// markers: true,
				}
			});
		});
	}

	const parallaxText = document.querySelectorAll('.js-parallax-text');

	if ( parallaxText ) {

		parallaxText.forEach(el => {

			const width = el.scrollWidth;
			const reverse = el.dataset.parallaxReverse;
			const adjust = ( isPc() ) ? 100 : 0 ;
			const x = ( reverse ) ? `${width - window.innerWidth + adjust}px` : `-${width - window.innerWidth + adjust}px`;

			gsap.to(el, {
				x: x,
				scrollTrigger: {
					trigger: el,
					start: `top+=100 bottom`,
					end: "bottom top",
					scrub: 1,
					// markers: true,
				}
			});
		});

	}

	const articles = document.querySelectorAll('.section-04-article');
	const figure = document.querySelector('.section-04__figure');
	const image = document.querySelectorAll('.section-04__image');

	if ( articles.length > 1 ) {

		image[0].classList.add('is-active');

		articles.forEach((article, i) => {
			gsap.to(article, {
				scrollTrigger: {
					trigger: article,
					start: 'top 60%',
					end: 'bottom 60%',
					// toggleClass: { targets: image[i], className: 'is-active'},
					onEnter: (e) => {
						image[i].classList.add('is-active');
					},
					// onEnterBack: (e) => {

					// },
					// onLeave: () => {

					// },
					onLeaveBack: () => {
						if ( i === 0 ) return false;
						image[i].classList.remove('is-active');
					},
					// markers: true,
				}
			});
		});

	}

}

const gsapLoad = () => {
	window.addEventListener('load', gsapAction);
}

/* slider
---------------------------------------------------------- */
import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';

const slider = () => {

	const sliders = document.querySelectorAll('.splide');

	if ( !sliders ) return false;

	const options = {
		hero01 : {
			type : 'loop',
			arrows: false,
			pagination: false,
			autoplay: true,
			rewind: true,
			drag: false,
			interval: 8000,
			speed: 3000,
			pauseOnHover: false,
		},
		hero02 : {
			type : 'loop',
			arrows: false,
			pagination: false,
			autoplay: true,
			rewind: true,
			drag: false,
			interval: 8000,
			speed: 3000,
			pauseOnHover: false,
		},
		anniversary : {
			type : 'fade',
			arrows: false,
			pagination: false,
			// paginationDirection: 'ttb',
			autoplay: false,
			rewind: true,
			drag: false,
			interval: 5000,
			speed: 3000,
			pauseOnHover: false,
			// classes: {
			// 	pagination: 'splide__pagination anniversary__pagination',
			// }
		},
		works : {
			arrows: true,
			arrowPath: 'M18.614,14.182 C18.314,14.480 18.320,14.964 18.627,15.254 L22.392,18.842 L13.411,18.842 C12.995,18.842 12.661,19.176 12.661,19.591 L12.661,20.592 C12.661,21.007 12.995,21.341 13.411,21.341 L22.392,21.341 L18.627,24.929 C18.323,25.220 18.317,25.704 18.614,26.001 L19.308,26.695 C19.598,26.989 20.073,26.989 20.367,26.695 L26.442,20.620 C26.736,20.329 26.736,19.854 26.442,19.561 L20.367,13.489 C20.077,13.195 19.601,13.195 19.308,13.489 L18.614,14.182 Z',
			pagination: false,
			speed: 1000,
			// paginationDirection: 'ttb',
			// rewind: true,
			classes: {
				arrows: 'splide__arrows works__arrows',
			}
		},
	};

	Object.keys(sliders).reverse().forEach( (i) => {
		const name = sliders[i].dataset.splideOption;
		const splide = new Splide( sliders[i] , options[name]);
		splide.mount();

		if ( name === 'anniversary' ) {

			const pagination = document.querySelector('.js-pagination');
			const pages = pagination.querySelectorAll('.slider-pagination__item');

			pages[0].classList.add('is-active');

			pages.forEach((page, i) =>  {
				page.addEventListener('animationiteration', () => {
					page.classList.remove('is-active');
					if ( i+1 === pages.length ) {
						pages[0].classList.add('is-active');
					} else {
						page.nextElementSibling.classList.add('is-active');
					}
					splide.go( '>' );
				});
			});

		} else if ( name === 'works' ) {

			const pager = document.querySelector('.js-works-pager');
			const current = pager.querySelector('.works-pager__current');
			const length = pager.querySelector('.works-pager__length');
			// const prev = pager.querySelector('.works-pager__button--prev');
			// const next = pager.querySelector('.works-pager__button--next');

			current.textContent = ( '00' + ( splide.index + 1 ) ).slice( -2 );
			length.textContent = splide.length;

			splide.on( 'move', () => {
				current.textContent = ( '00' + ( splide.index + 1 ) ).slice( -2 );
			});
		}

	});
}

//* cursorFollow
//---------------------------------------------------------- */
const cursorFollow = () => {

	const body = document.body;

	const container = document.querySelector('.js-cursor-follow');

	if ( !container ) return false;

	const details = container.querySelectorAll('.interview-section-item')
	const item = container.querySelector('.js-cursor-follow__item');

	container.addEventListener('mousemove', (e) => {itemPosition(e)});
	container.addEventListener('mouseenter', (e) => {
		item.classList.add('is-view')
		itemPosition(e)
	});
	container.addEventListener('mouseleave', (e) => {
		item.classList.remove('is-view')
	});

	function itemPosition(e) {
		item.style.left = e.clientX - 60 + "px";
		item.style.top = e.clientY - 70 + "px";
	};

	details.forEach((detail, i) => {

		const className = `is-mouseenter-detail-${i}`;

		detail.addEventListener('mouseenter', (e) => {
			body.classList.add(className);
		});
		detail.addEventListener('mouseleave', (e) => {
			body.classList.remove(className);
		});
	});

};

/* scrollable
---------------------------------------------------------- */
import ScrollHint from 'scroll-hint'
import 'scroll-hint/css/scroll-hint.css';
const scrollable = () => {
	new ScrollHint('.js-scrollable', {
		i18n: {
			scrollable: 'スクロールできます',
		}
	});

}

/* setFillHeight
---------------------------------------------------------- */
const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
  // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

/* resizeEnd
---------------------------------------------------------- */
const event = new CustomEvent('resizeend');
const currentWidth = window.innerWidth;
let timeoutID = 0;
let delay = 500;

window.addEventListener("resize", () => {

	if ( currentWidth === window.innerWidth ) return false;

	clearTimeout(timeoutID);
	timeoutID = setTimeout( () => {
		window.dispatchEvent(event);
	}, delay);

}, false);

/* mediaQuery
---------------------------------------------------------- */
const isPc = () => {

	const mq = ( window.matchMedia( "(min-width: 768px)" ).matches ) ? true : false;
	return mq;

}

webfont();
gnavToggle();
disableScroll();
inView();
spanWrap();
opening();
// accordion();
gsapLoad();
slider();
cursorFollow();
// scrollable();
setFillHeight();
// factory();